<template>
  <div>
    <vs-table
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total">
      <template slot="thead">
        <vs-th sort-key="">Role</vs-th>
        <vs-th sort-key="">Name</vs-th>
        <vs-th sort-key="">Status</vs-th>
        <vs-th sort-key="">Date Time</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ tr.role }}</vs-td>
          <vs-td>{{ tr.name }}</vs-td>
          <vs-td>{{ tr.status }}</vs-td>
          <vs-td>{{ tr.date_time }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>

</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    draw: {
      type: Number,
    },
    selectedApprovalType: {
      type: Object,
      default: null,
    },
    selectedDocumentNumber: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      deleteId: null,
      action: null,
      table: this.tableDefaultState(),
      checkedAll: false,
      checked: [],
      selectedRouteAssignmentToAssign: [],
      activeTab: 0,
      lastDraw: 0,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    getData() {
      if (this.draw !== this.lastDraw) {
        this.lastDraw = this.draw;
        this.$vs.loading();
        this.$http
          .get("/api/wms/v1/approval-history/history-data", {
            params: {
              approval_type_id: this.selectedApprovalType.id,
              reference_code: this.selectedDocumentNumber.reference_code,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.history;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    onchangeSelectedRouteAssignmentToAssign(e, index, row) {
      if (!this.checked[index]) {
        var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
          return d.id == row.id;
        });

        if (fitered.length == 0) {
          this.selectedRouteAssignmentToAssign.push(row);
        }
      } else {
        this.selectedRouteAssignmentToAssign.forEach((val, i) => {
          if (val.id == row.id) {
            this.selectedRouteAssignmentToAssign.splice(i, 1);
          }
        });
        this.checkedAll = false;
      }
    },
    clearTableData() {
      this.table.data = [];
      this.table.total = 0;
      this.table.totalPage = 1;
      this.table.totalSearch = 0;
      this.table.length = 10;
      this.table.page = 1;
      this.table.start = 1;
      this.table.end = 0;
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    draw(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getData();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
