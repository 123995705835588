<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Approval Type</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <ApprovalType
            :value="selectedApprovalType"
            @selected="(val) => { selectedApprovalType = val }"
          ></ApprovalType>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Document Number</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <DocumentNumber
            :approvalTypeID="selectedApprovalType ? selectedApprovalType.id : null"
            :value="selectedDocumentNumber"
            @selected="(val) => { selectedDocumentNumber = val }"
          ></DocumentNumber>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
        </div>
      </div>
    </div>

    <div class="vx-row mt-3">
      <div class="vx-col w-full mb-base">
        <Tables
          ref="tables"
          :draw="draw"
          :selectedApprovalType="selectedApprovalType"
          :selectedDocumentNumber="selectedDocumentNumber"
        ></Tables>
      </div>
    </div>
  </div>
</template>

<script>
import ApprovalType from "../component/ApprovalType.vue";
import DocumentNumber from "../component/DocumentNumber.vue";
import Tables from "../component/Table.vue";

export default {
  components: {
    ApprovalType,
    DocumentNumber,
    Tables,
  },
  data() {
    return {
      draw: 0,
      selectedApprovalType: null,
      selectedDocumentNumber: null,
    };
  },
  methods: {
    handleFilter() {
      this.draw++;
    },
  },
  watch: {
    selectedApprovalType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedDocumentNumber = null;
      }
    },
    selectedDocumentNumber(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.tables.clearTableData();
      }
    },
  },
};
</script>
